.collapsible {
  & .collapsible--header {
    @apply flex items-center cursor-pointer py-10;

    & .icon {
      @apply transition-transform duration-200;
      background-size: 70%;
    }
  }

  & .collapsible--content {
    @apply hidden opacity-0 duration-300 delay-500 transition-opacity;
  }

  & > input[type='checkbox']:checked + .collapsible--header .icon {
    @apply rotate-0;
  }

  &
    > input[type='checkbox']:checked
    + .collapsible--header
    + .collapsible--content {
    @apply flex opacity-100;
  }
}
