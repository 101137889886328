@layer components {
  .btn {
    @apply text-black border-black font-bold py-3 px-5 inline-flex justify-center content-center items-center transition ease-in-out duration-300 outline-none no-underline m-0 bg-no-repeat bg-center rounded-25 font-title;
    line-height: 1;

    & > .btn-label {
      @apply inline-block select-none; /* inline-block needed to honor ::first-letter*/

      &::first-letter {
        @apply uppercase;
      }
    }

    &.btn\:disabled,
    &.btn--disabled,
    &:disabled {
      @apply opacity-25 pointer-events-none cursor-not-allowed;
    }
  }

  .btn-text {
    @apply text-sm font-secondary p-0 rounded-none border-0 border-b-2 border-transparent bg-transparent inline-flex select-none;
  }

  .btn-text-sm {
    @apply text-sm font-body;
  }

  .btn-text--active {
    @apply border-black bg-transparent;
  }

  .btn-contained {
    @apply bg-black text-white shadow;

    &.btn-text {
      @apply border-none;

      /* TEST : all secondary buttons (ie. text buttons, with or without emojis)
          should have the first letter capitalized */
      &::first-letter {
        @apply capitalize bg-red;
      }
    }

    &:active,
    &.btn--active {
      @apply bg-dark-gray;
    }
  }

  .btn-outline {
    @apply border-2 border-black;
  }

  .btn-outline:active,
  .btn-outline.btn--active {
    @apply bg-black text-white;
  }

  .btn-outline--ghost {
    @apply border-opacity-25 border-dashed bg-transparent;

    &:active,
    &.btn--active {
      @apply bg-black text-white;
    }
  }

  .btn--light {
    @apply border-white text-white;

    &.btn-contained {
      @apply bg-white text-black;
    }
  }

  .btn--busy {
    @apply pointer-events-none cursor-wait;

    & .icon-spinner,
    & .spinner {
      @apply inline-block visible;
    }
  }

  .btn-icon {
    @apply justify-around;

    &.btn-xs {
      @apply p-1 h-8;

      &:not(.btn-text) {
        @apply w-8;
      }
    }

    &.btn-contained {
      & .icon.icon--mono {
        mix-blend-mode: difference;
      }
    }

    &.btn-icon-only:not(.rounded-l-none) {
      @apply rounded-full;

      &.btn-sm {
        @apply p-2;

        & .icon {
          @apply w-6 h-6;
        }
      }

      &.btn-md {
        @apply p-3;
      }

      &.btn-lg {
        @apply p-5;
      }
    }
  }

  .btn-round {
    @apply bg-transparent rounded-full;
    padding: 10px;
  }

  .btn-xs {
    @apply text-xs font-secondary;
    padding: 6px 12px;
  }

  .btn-sm {
    @apply text-sm;
    padding: 18px 20px;
  }

  .btn-md {
    @apply rounded-35;
    padding: 24px 30px;
  }

  .btn-xl {
    @apply text-xl;
  }

  .btn-group {
    @apply flex justify-center content-center items-center;

    & > .btn {
      @apply mr-4;
    }
  }

  .btn-tool {
    @apply p-0 flex-col text-micro;
    /* When the icon isn’t a child but is set directly to the button background */
    background-size: 70%;

    & .icon {
      min-width: 16px;
      min-height: 16px;
      margin: 4px;
    }

    &:hover {
      @apply bg-gray opacity-75;
    }

    &:active,
    &.btn--active {
      @apply bg-black text-white;
    }
  }

  .btn-nav {
    @apply p-0 border-4 bg-transparent rounded-full;
    width: 40px;
    height: 40px;
  }

  .btn-danger {
    @apply text-red;

    &:hover {
      @apply border-red;
    }

    &.btn-contained {
      @apply bg-burgundy text-white;

      &:hover {
        @apply opacity-100 bg-pink;
      }
    }

    &.btn-outline {
      @apply border-burgundy text-burgundy bg-white;
    }

    &.bg-transparent {
      @apply bg-transparent;
    }
  }

  .btn-success {
    @apply text-green-sage;

    &:hover {
      @apply border-green-sage;
    }

    &.btn-contained {
      @apply bg-green-olive text-white;

      &:hover {
        @apply opacity-100 bg-green-sage;
      }
    }

    &.btn-outline {
      @apply border-green-olive text-green-olive bg-white;
    }

    &.bg-transparent {
      @apply bg-transparent;
    }
  }

  .btn-round-md {
    width: 70px;
    height: 70px;
    background-size: 10px;
  }

  .btn-round-lg {
    @apply w-28 h-28;
    background-size: 10px;
  }

  .btn-round-xl {
    @apply border-2 flex-col uppercase text-xs w-36 h-36;
    background-size: 10px;

    & .icon {
      @apply mb-1;
    }
  }

  .btn-fixed-left {
    @apply rounded-full rounded-l-none fixed left-0;
    width: 50px;
    background-position: 15px;
  }

  .btn-user {
    @apply bg-black;
    width: 40px;
    height: 40px;
  }

  .btn-card {
    @apply flex-col text-center;
  }

  .btn-edge-back {
    @apply rounded-full rounded-l-none absolute left-0 top-0 z-20 -ml-3;
  }

  .btn-group {
    & > .btn,
    & > button,
    & > li > .btn {
      @apply rounded-none;
    }
  }

  /* Hover effects only for non-touch devices */
  /* https://ferie.medium.com/detect-a-touch-device-with-only-css-9f8e30fa1134 */
  @media (hover: hover) {
    .btn:hover {
      @apply shadow-none; /* gray work on both dark and light icons*/
    }

    .btn-text:hover,
    .btn-text--active {
      @apply border-black bg-transparent;
    }

    .btn-contained:hover {
      @apply bg-dark-gray;
    }

    .btn-outline:hover {
      @apply bg-black text-white;
    }

    .btn-outline--ghost:hover {
      @apply border-opacity-100 border-solid bg-transparent border-black;
    }

    .btn--light:hover {
      @apply bg-dark-gray; /* QUESTION: bg-color gray work on most parent bg ? */
    }

    .btn-color:hover {
      box-shadow: 0 0 0 4px inset white;
    }

    .btn-card:hover {
      @apply shadow;

      & .icon-multi .icon:first-child {
        @apply opacity-0;
      }
      & .icon-multi .icon:last-child {
        @apply opacity-100;
      }
    }
  }
}
