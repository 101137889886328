@layer base {
  @font-face {
    font-family: ManropeMedium;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url(../fonts/Manrope/Manrope-Medium.woff2) format("woff2");
  }

  @font-face {
    font-family: ManropeExtraBold;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url(../fonts/Manrope/Manrope-ExtraBold.woff2) format("woff2");
  }

  @font-face {
    font-family: MintGroteskExtraBold;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    font-display: swap;
    src: url(../fonts/MintGrotesk-V08/MintGroteskV0.8-ExtraBold.woff2) format("woff2");
  }
}
