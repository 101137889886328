form {
  @apply relative;

  &.form--busy {
    @apply pointer-events-none cursor-wait;

    & input[type='submit'],
    & button[type='submit'] {
      @apply opacity-75;

      & .spinner {
        @apply inline-block;
      }
    }

    & .form-spinner {
      @apply block;
    }
  }

  &.form--error .alert--error {
    @apply block;
  }

  &.form--success .alert--success {
    @apply block;
  }

  &.form--inactive {
    @apply pointer-events-none;

    & button {
      @apply opacity-50;
    }
  }
}
