/* https://github.com/TrySound/postcss-inline-svg/issues/72 */
.splash-container {
  @apply bg-no-repeat;
  background: svg-load('../../img/shapes/triangles.svg') -20% 0 no-repeat,
    svg-load('../../img/shapes/triangles.svg') 90% 0 no-repeat,
    svg-load('../../img/shapes/circles.svg') 110% 50% no-repeat,
    svg-load('../../img/shapes/diamonds-red.svg') 10% 100% no-repeat,
    svg-load('../../img/shapes/diamonds-blue.svg') 102% 100% no-repeat;
  background-size: 50vw;
}

@media (min-width: 1000px) {
  .splash-container {
    background-size: 33vw;
  }
}

@media (min-width: 1200px) {
  .splash-container {
    @apply bg-auto;
  }
}

.testimonial {
  @apply bg-no-repeat bg-top;
  background-image: svg-load('../../img/shapes/waves.svg');
}

.bg-circle-shape {
  border-radius: 100%;
  transform: translate(-50%, 50%);
  z-index: -1;
  left: 50%;
}
