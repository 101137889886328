.pointer-cut {
  cursor: svg-load('../../img/icons/cut-reference.svg', fill=var(--black)), auto;
}

.icon {
  @apply not-italic inline-block bg-no-repeat bg-center bg-contain select-none;
  line-height: normal;
  min-width: theme('height.6');
  min-height: theme('width.6');

  &:disabled {
    @apply opacity-25 pointer-events-none cursor-not-allowed;
  }
}

.icon-pre {
  @apply mr-2;
}

.icon-post {
  @apply ml-2;
}

.icon-emoji {
  font-size: 15px;
  min-height: auto;
}

@each $icon in copy, cut, copy-cut, delete, edit, vol-pan, plus, play, pause,
  stop, backtostart, arrow-left, arrow-right, arrow-down, arrow-up,
  arrow-up-thick, arrow-left-thin, arrow-right-thin, import, user, close, move,
  check, facebook, twitter, email, crosshairs-gps, export, sound-on, sound-off,
  solo, fx, more, import-file, library-music, repeat, repeat-once, rewind,
  forward, error, micro-off, file-import-outline, file-export-outline,
  file-music-outline, music-note-eighth, book-open-page-variant, map-md,
  animation-play, playlist-music-outline, timer-sand, link-variant, edit
{
  .icon-$(icon) {
    background-image: svg-load(
      '../../img/icons/$(icon).svg',
      fill=var(--black)
    );
  }
  .icon-$(icon)--light {
    background-image: svg-load('../../img/icons/$(icon).svg', fill=#FFF);
  }
}

@each $icon in delete, error {
  .icon-$(icon)--danger {
    background-image: svg-load(
      '../../img/icons/$(icon).svg',
      fill=#991d1d
    ); /* red-burgundy */
  }
}

@each $icon in echo-30, text, image, cover-color, location-color, import-color,
  avatar-wink, avatar-user-2, avatar-user, story, story-color, map, map-color
{
  .icon-$(icon) {
    background-image: svg-load('../../img/icons/$(icon).svg');
  }
}

/* Only background input that need a special variant */
input:focus.focus\:icon-none {
  background-image: none;
  cursor: auto;
}

.icon-rounded {
  @apply rounded-full;
  background-size: 60%;
}

.icon-spinner {
  background-image: svg-load('../../img/tail-spin.svg');
  min-width: 18px;
  min-height: 18px;
}

.icon-import-color {
  width: 68px;
  height: 70px;
}

.icon-location-color {
  width: 86px;
  height: 86px;
}

.icon-multi {
  @apply relative;

  & > .icon {
    @apply absolute top-0 left-0 opacity-0 transition-opacity duration-300 ease-in-out w-full h-full;
  }

  & .icon:first-child {
    @apply static opacity-100;
  }
}
