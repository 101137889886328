/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: black #727272;
} /* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #727272;
}
*::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 0;
  border: none;
}

.scrollable {
  scrollbar-gutter: stable;
  scroll-behavior: scroll;
}

.scrollbar--light {
  /* Firefox */
  & {
    scrollbar-color: white #727272;
  } /* Chrome, Edge, and Safari */

  &::-webkit-scrollbar-thumb {
    background-color: white;
  }
}
