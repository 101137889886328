.spinner {
  /* Needed to weight .visible selector overe .invisible */
  &.visible {
    @apply visible;
  }

  &.spinner-xs {
    @apply w-4 h-4;
  }
}
