@import 'tailwindcss/utilities';
@import 'vendor.pcss';

@layer utilities {
  .cap-first-letter {
    &::first-letter {
      @apply uppercase;
    }
  }

  .m-auto {
    /* "auto" cant be used as a keyword in config */
    margin: 'auto';
  }

  .h-relative-screen {
    height: calc(100vh - var(--menu-height) * 2);
  }
}
