/* Style below have to be sorted/cleaned/moved */

:root {
  --menu-height: 100px;
}

body {
  /* Transition for story preview background color */
  @apply transition-colors duration-500 ease-out;
}

#main-nav {
  height: var(--menu-height);
}

main {
  &.loading {
    @apply opacity-75 cursor-wait pointer-events-none;

    > .spinner {
      @apply block;
    }
  }
}

.container-sm {
  @apply mx-auto max-w-192;
}

audio {
  @apply hidden;
}

svg {
  width: 100%;
}

img {
  /* Alt text styling */
  @apply text-xs overflow-hidden text-transparent;
}

.logo {
  width: 91px;
  height: 30px;
}

.action-card {
  max-width: 400px;
}

.cards-grid {
  grid-template-columns: repeat(auto-fit, minmax(theme('spacing.70'), 1fr));
}

.new-page {
  width: 280px;
  height: 380px;
}

.transform-none {
  transform: none;
}

#loginForm-modal {
  top: var(--menu-height);
}

.user-avatar-sm {
  @apply h-full w-full object-cover text-transparent;
}

.has-touch:not(.has-keyboard) .touch\:visible {
  @apply flex;
}

#touch-warning-msg .modal button > span:after {
  @apply block;
  content: attr(data-label);
}

.ignore-touch-warning #touch-warning-msg {
  @apply w-auto h-12 visible flex bg-transparent top-0;

  font-size: 0;

  & * {
    @apply hidden p-0 m-0;
  }

  & .modal {
    @apply flex rounded-tl-none rounded-bl-none border-white border border-l-0;

    & button {
      @apply flex p-1 m-1 mr-2 bg-transparent bottom-0;
  
      & > span {
        @apply flex;

        &:after {
          @apply text-lg;
          content: attr(data-label-min);
        }
      }
    }
  }
}
