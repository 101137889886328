@layer base {
  input[type='checkbox'] {
    @apply p-0 relative box-content border-2 h-full border-gray outline-none cursor-pointer appearance-none;
    width: 66px;
    border-radius: 20px;
    padding: 2px 0;
  }
}

.switch {
  @apply uppercase inline-flex text-xs font-secondary;
  height: 36px;

  & input {
    & + span {
      @apply opacity-50;
    }

    &::after {
      @apply block absolute rounded-full bg-black;
      content: '';
      transition: transform 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
      width: 28px;
      height: 28px;
      top: 4px;
      left: 5px;
      box-shadow: 0 5px 10px 0 rgba(29, 29, 29, 0.3);
    }

    &:checked {
      &::after {
        transform: translateX(100%);
      }

      & + span {
        @apply opacity-100;
      }
    }
  }

  &.switch-sm {
    height: 18px;

    & input {
      width: 33px;

      &::after {
        width: 14px;
        height: 14px;
        top: 2px;
        left: 3px;
        box-shadow: 0 2px 5px 0 rgba(29, 29, 29, 0.3);
      }
    }
  }
}
