
.drop-target {
    @apply relative;

    &::after {
      content: ' ';
      @apply block absolute w-full h-full border-4 opacity-0 top-0 left-0 z-0 transition duration-200 ease-in-out;
      border-radius: inherit;
      transform: scale(1)
    }
}

@keyframes breathe {
  0% {
    @apply opacity-0;
    transform: scale(1.04)
  }
  50% {
    @apply opacity-75;
  }
  100% {
    @apply opacity-0;
    transform: scale(1.24)
  }
}

.drop-target--enter::after {
  animation: breathe 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.drop-target--over::after {
  @apply opacity-75;
  transform: scale(1.2)
}
