.paragraph-secondary {
  & ol,
  & ul {
    @apply ml-12 my-8;
  }

  & li {
    @apply pl-8 relative;

    &::before {
      @apply block absolute -mr-8 top-0 left-0;
      content: '•';
    }
  }

  & p {
    @apply my-8;
  }

  & h6 {
    @apply my-4;
  }

  & a:link {
    @apply underline font-bold;
  }
}

@media screen and (min-width: 1024px) {
  .paragraph-secondary {
    & ol,
    & ul {
      @apply ml-24;
    }
  }
}
