
@layer base {
  select {
    @apply bg-transparent p-0 m-0 w-full border-none outline-none;
    appearance: none;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    /* Stack above custom arrow */
    z-index: 1;

    /* Remove dropdown arrow in IE10 & IE11
    @link https://www.filamentgroup.com/lab/select-css.html */
    &::-ms-expand {
      display: none;
    }
  }
}

@layer components {
  select {
    @apply h-full;
  }

  .select {
    height: 70px;
    border-radius: 35px;

    & .icon {
      right: 20px;
    }
  }

  .select--light {
    @apply bg-white text-black;
  }

  .select--lg {
    @apply w-full;
    font-size: 20px;
  }
}
