@layer base {
  body {
    @apply font-body text-black text-base;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .text-heading1 {
    @apply font-title text-huge;

    &::first-letter {
      @apply uppercase;
    }
  }

  h2,
  .text-heading2 {
    @apply text-6xl;
  }

  h3,
  .text-heading3 {
    @apply text-4xl font-title;
  }

  h4,
  .text-heading4 {
    @apply text-2xl;
  }

  h5,
  .text-heading5 {
    @apply text-xl;
  }

  h6,
  .text-heading6 {
    @apply text-base font-title;
    line-height: 1.33;
  }

  @media (max-width: 1024px) {
    h1,
    .text-heading1 {
      @apply text-6xl;
    }

    h2,
    .text-heading2 {
      @apply text-4xl;
    }

    h3,
    .text-heading3 {
      @apply text-2xl;
    }

    h4,
    .text-heading4 {
      @apply text-xl;
    }

    h5,
    .text-heading5 {
      @apply text-base;
    }
  }
}

.text-body-2 {
  @apply font-body text-sm;
}

.text-caption {
  @apply font-body text-xs;
}

.text-overline {
  @apply font-secondary text-xs;
}

.text-subtitle-1 {
  @apply font-secondary text-base;
}

.text-subtitle-2 {
  @apply font-secondary text-sm;
  line-height: normal;
  letter-spacing: normal;
}

.text-hero {
  @apply font-title text-giant;
}

.ellipsis-fade-line {
  @apply relative;
  background: inherit;
}
.ellipsis-fade-line::after {
  @apply block absolute bottom-0 left-0 w-full;
  content: ' ';
  height: 3em;
  background: inherit;
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1)
  );
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}
.ellipsis-fade-line-xs::after {
  height: 1em;
}

.capitalize-first::first-letter {
  @apply capitalize;
}
