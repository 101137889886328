/* From : https:/*github.com/franciscop/picnic/blob/master/src/plugins/tooltip/_plugin.scss */

/* Variables for the tooltips */

.label {
  @apply rounded-2;

  /* @extend %label; */
  display: inline-block;
  text-align: center;
  letter-spacing: inherit;
  margin: 1em;
  padding: 1em;
  vertical-align: middle;
  background: var(--picnic-tooltip-background);
  color: white;
  border: 0;
  width: auto;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-size: 0.6em;
  padding: 0.4em 0.6em;
  margin-left: 1em;
  line-height: 1;
}

/* Only display the tooltip for elements with <element data-tooltip="blabla"> */
[data-tooltip] {
  --picnic-tooltip-size: 0.8em;
  --picnic-separation: 0.6em;
  --picnic-tooltip-background: rgba(var(--rgb-black), 0.8);

  position: relative;

  /* Disable opacity effect from parent */
  &:hover {
    &:after {
      @apply opacity-100;
    }
  }

  &:after,
  &:before {
    @apply text-xs whitespace-normal;

    /* Position */
    position: absolute;
    z-index: 10;

    /* Hide it by default */
    opacity: 0;
    border-width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;

    /* Transition */
    transition: opacity 0.6s ease, height 0s ease 0.6s;

    /* Bottom one (default) */
    top: calc(100% - 6px);
    left: 0;
    margin-top: 12px;
  }

  /* This is the acutal label-like element */
  &:after {
    @apply normal-case text-left w-full;

    /* Style */
    @extend .label;
    margin-left: 0;
    /* font-size: var(--picnic-tooltip-size); */
    background: var(--picnic-tooltip-background);

    /* Content */
    content: attr(data-tooltip);

    /* Behaviour */
    /* white-space: nowrap; */
  }

  /* This is the small arrow */
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-width: 0;
    border-style: solid;

    /* Bottom one (default) */
    border-color: transparent transparent var(--picnic-tooltip-background);
    margin-top: 0;
    left: 50%;
  }

  &:hover:after,
  &:focus:after,
  &:hover:before,
  &:focus:before {
    opacity: 1;
    border-width: 6px;
    height: auto;
  }

  &:hover:after,
  &:focus:after {
    padding: 1em;
  }
}

.tooltip-w-200::after {
  width: 200%;
}

.tooltip-w-28::after {
  @apply w-28 text-center;
}

/* Top */
.tooltip-top {
  &:after,
  &:before {
    top: auto;
    bottom: calc(100% - 6px);
    left: 0;
    margin-bottom: 12px;
  }

  &:before {
    border-color: var(--picnic-tooltip-background) transparent transparent;
    margin-bottom: 0;
    left: 50%;
  }
}

.tooltip-danger {
  &:before {
    border-color: rgba(var(--rgb-red-cherry), 0.9) transparent transparent;
  }

  &:after {
    background: rgba(var(--rgb-red-cherry), 0.9);
  }
}
