.alert {
  @apply p-2 rounded-2 max-w-screen-sm mx-auto transition text-sm text-white;
}

.alert--success {
  @apply bg-green-sage;
}

.alert--error {
  @apply bg-red;
}
