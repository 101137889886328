@layer components {
  input,
  textarea {
    @apply text-black border border-white appearance-none rounded-2 bg-white block text-left;

    &.input-2xl {
      @apply text-2xl font-title;

      &::placeholder {
        @apply text-2xl font-title text-gray;
      }
    }

    &.input-4xl {
      @apply text-4xl font-title;

      &::placeholder {
        @apply text-4xl font-title text-gray;
      }
    }
  }

  textarea {
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      @apply text-dark-gray;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      @apply text-dark-gray;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      @apply text-dark-gray;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      @apply text-dark-gray;
    }
  }

  .input label::first-letter,
  .input__extra::first-letter {
    @apply uppercase;
  }

  .input.is-valid:after {
    @apply block absolute right-0 top-0 mt-6 mr-5;
    content: svg-load('../../img/icons/check.svg', fill=var(--black));
    width: 24px;
    height: 24px;
  }

  input.has-error {
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
      var(--rgb-red-burgundy) 0px 0px 0px 2px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }

  input[type='file'] {
    @apply hidden;
  }

  input[type='range'] {
    @apply w-full bg-transparent m-0 p-0 border-none h-6;
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type='range']::-ms-track {
    @apply w-full cursor-pointer bg-transparent border-none text-transparent;
  }

  /* Special styling for WebKit/Blink */
  input[type='range'] {
    &::-webkit-slider-thumb {
      @apply border-none h-6 w-6 rounded-full bg-white cursor-pointer shadow;
      -webkit-appearance: none;
      margin-top: -10px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    }

    &.range-sm::-webkit-slider-thumb {
      @apply h-4 w-4;
      -webkit-appearance: none;
      margin-top: -6px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    }
  }

  /* All the same stuff for Firefox and IE */
  input[type='range']::-moz-range-thumb,
  input[type='range']::-ms-thumb {
    @apply border-none h-6 w-6 rounded-full bg-white cursor-pointer shadow;
  }

  input[type='range']::-webkit-slider-runnable-track {
    @apply w-full h-1 bg-gray cursor-pointer border-none rounded-2;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    @apply bg-gray border-none rounded-2;
  }

  input[type='range']::-moz-range-track {
    @apply w-full h-1 bg-gray cursor-pointer border-none rounded-2;
  }

  input[type='range']::-ms-track {
    @apply w-full h-1 bg-transparent text-transparent border-none cursor-pointer;
  }
  input[type='range']::-ms-fill-lower,
  input[type='range']:focus::-ms-fill-lower,
  input[type='range']::-ms-fill-upper,
  input[type='range']:focus::-ms-fill-upper {
    @apply bg-gray border-none;
  }
}
