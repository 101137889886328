:root {
  --black: #1d1d1d;
  --dark-gray: #8e8e8e;
  --gray: #d0d0d0;
  --light-gray: #f1f1f1;
  --white: #ffffff;
  --blue-arctic: #bcd5d8;
  --blue-turkish: #1d79af;
  --green-olive: #526236;
  --green-sage: #abd186;
  --lavender: #b0b0ea;
  --orange-neon: #ff5f02;
  --pink: #f7b0be;
  --red-burgundy: #991d1d;
  --yellow-warm: #f9ca29;
  --red-cherry: #ee3a24;
  /* Tailwind doesnt support rgb var color, need to redeclare it */
  --rgb-black: 29, 29, 29;
  --rgb-dark-gray: 142, 142, 142;
  --rgb-gray: 208, 208, 208;
  --rgb-light-gray: 241, 241, 241;
  --rgb-white: 255, 255, 255;
  --rgb-blue-arctic: 188, 213, 216;
  --rgb-blue-turkish: 29, 121, 175;
  --rgb-green-olive: 82, 98, 54;
  --rgb-green-sage: 171, 209, 134;
  --rgb-lavender: 176, 176, 234;
  --rgb-orange-neon: 255, 95, 2;
  --rgb-pink: 247, 176, 190;
  --rgb-red-burgundy: 153, 29, 29;
  --rgb-yellow-warm: 249, 202, 41;
  --rgb-red-cherry: 238, 58, 36;
}

/* Test workaround for CSP restrictions of inline styles */
/* ex: src/templates/stories/_components/storyCard.twig */
.bg-526236 {
  @apply bg-green-olive;
}
